import { useMutation } from '@tanstack/react-query';
import { getInvoice } from '../../API/invoiceApi';

export const useDownloadInvoice = () =>
  useMutation((id) => getInvoice({ id }), {
    onSuccess: (blob) => {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    onError: (error) => {
      console.error('Error downloading invoice:', error.message);
    },
  });
