import axios from '../utils/axios';

export const fetchOptimizationLogs = async (optimizationId) => {
  try {
    const response = await axios.get(`/api/optimization/${optimizationId}/logs`);
    return response.data;
  } catch (error) {
    console.error('Error fetching optimization logs:', error);
    return [];
  }
};
