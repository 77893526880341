import React, { Fragment } from 'react';
import styled from '@emotion/styled';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Button,
  Typography,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Collapse,
  Tooltip,
  IconButton,
  Alert,
} from '@mui/material';
import { spacing } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';

import DatePopup from '../DatePopup';
import SortingTableCell from '../SortingTableCell';
import { LEADS_EXPECTATION_NAMES } from '../../constants/leadsExpectationNames';
import useClientCompanyList from '../../hooks/useClientCompanyList';
import CompanySitesTable from '../CompanySiteTable';
import ChangeOptimizationStageButton from '../OptimizationSiteTableComponents.js/ChangeOptimizationStageButton';
import { getKeyObjectByValue } from '../../utils/getKeyObjectByValue';
import { OPTIMIZATION_STAGE } from '../../constants/optimizationsStage';
import CompanyCard from './CompanyCard';
import CompanyTableRowWithOptimization from './CompanyTableRowWithOptimization';
import { createExcelFile } from '../../utils/createExcelFile';
import { EXCEL_TYPES } from '../../constants/excelTypes';
import LeadExpectationMenu from '../LeadExpectationMenu';
import { EXCEL_EMAILS } from '../../constants/excelEmails';
import TextAreaWithSignatureButton from '../OptimizationSiteTableComponents.js/TextAreaWithSignatureButton';
import { PAYER_STATUS } from '../../constants/payerStatus';
import CalendarPopup from '../CalendarPopup';
import InfoIconWithTooltip from '../InfoIconWithTooltip';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  max-height: 100%;
`;

const headerTableCellStickyStyle = {
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 1,
  fontSize: '10px',
  padding: '6px',
};

const TableCell = styled(MuiTableCell)`
  padding: 6px;
  font-size: 10px;
`;

const ClientsCompaniesList = () => {
  const {
    clientCompaniesList,
    search,
    page,
    count,
    limit,
    openDateFilter,
    dateFilter,
    currentSalespersonId,
    expandedId,
    type,
    loadingCell,
    sort,
    reverseSort,
    isDesktop,
    salespersons,
    newComment,
    anchorEl,
    openSelect,
    updateLoader,
    userInfo,
    openImmunityCalender,
    setOpenImmunityCalender,
    onCloseLeadExpectationMenu,
    setAnchorEl,
    onChangeLeadsExpectation,
    setOpenSelect,
    setNewComment,
    setCurrentSalespersonId,
    setOpenDateFilter,
    changeSort,
    changeDateFilter,
    searchInputHandler,
    typeHandler,
    getSalespersonNamesString,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
    openUserView,
    changeComment,
    onChange,
    setExpandedId,
    changeImmunityDate,
  } = useClientCompanyList();

  return (
    <div
      style={{
        height: isDesktop ? 'calc(100vh - 60px)' : '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          flexWrap: 'wrap',
        }}>
        <Typography
          sx={{
            fontWeight: 500,
            '@media (max-width: 600px)': {
              fontSize: '30px',
            },
          }}
          variant="h2">
          Kundföretag
        </Typography>{' '}
        {EXCEL_EMAILS.includes(userInfo.email) && (
          <Button style={{ float: 'right' }} onClick={() => createExcelFile(EXCEL_TYPES.COMPANIES)}>
            Download in Excel
          </Button>
        )}
      </div>
      <Card
        mb={6}
        sx={{
          maxWidth: '100%',
          '@media (min-width: 960px)': {
            maxWidth: 'calc(100vw - 205px)',
          },
          '@media (min-width: 1280px)': {
            maxWidth: 'calc(100vw - 242px)',
          },
          background: isDesktop ? 'white' : 'transparent',
          border: '1px solid',
          borderColor: isDesktop ? 'grey.300' : 'transparent',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: !isDesktop && 'none',
          fontSize: '10px',
        }}>
        <div
          style={{
            margin: '16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box sx={{ display: 'flex', gap: '16px 0', flexWrap: 'wrap' }}>
            <TextField
              sx={{ marginBottom: 0, marginRight: '15px', background: 'white' }}
              id="standard-basic"
              label="Sök företag"
              variant="outlined"
              onChange={searchInputHandler}
              value={search}
              size="small"
            />
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <InputLabel>Client status</InputLabel>
              <Select
                size="small"
                sx={{ background: 'white' }}
                value={type}
                onChange={typeHandler}
                label="Client status">
                <MenuItem
                  value="active"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  Active
                  <span> {count.isActive} </span>
                </MenuItem>
                <MenuItem
                  value="new"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  New
                  <span> {count.isNew} </span>
                </MenuItem>
                <MenuItem
                  value="successful"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  Successful
                  {/* <span> {count.isNew} </span> */}
                </MenuItem>
                <MenuItem
                  value="bad_payers"
                  sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                  Dålig betalare
                  {/* <span> {count.isNew} </span> */}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <InputLabel id="salesperson-label">Kunder</InputLabel>
              <Select
                sx={{ background: 'white' }}
                disabled={type === 'new'}
                size="small"
                labelId="salesperson-label"
                value={currentSalespersonId}
                onChange={(e) => setCurrentSalespersonId(e.target.value)}
                label="Kunder">
                <MenuItem value={0}>All Kunder</MenuItem>
                {salespersons?.map((sales) => (
                  <MenuItem key={sales.id} value={sales.id}>
                    {sales.firstName} {sales.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '150px', marginRight: '15px' }}>
              <Button
                disabled={type === 'new'}
                variant="outlined"
                onClick={() => setOpenDateFilter(true)}
                sx={{
                  flexGrow: 1,
                  borderColor: '#BBBBBB',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 400,
                  '&:hover': {
                    borderColor: '#575757',
                    backgroundColor: '#fff',
                  },
                  backgroundColor: '#fff',
                }}>
                Filtrera på datum
              </Button>
              <DatePopup
                open={openDateFilter}
                onClose={() => setOpenDateFilter(false)}
                data={dateFilter}
                setDate={(data) => changeDateFilter(data)}>
                <MenuItem value="off">Inget filter</MenuItem>
                <MenuItem value="agreement_start_date">Live datum</MenuItem>
                <MenuItem value="notice_date">Uppsägningsdatum</MenuItem>
                <MenuItem value="agreement_end_date">Slutdatum</MenuItem>
                <MenuItem value="renewal_date">Förnyelsedatum</MenuItem>
              </DatePopup>
            </FormControl>
          </Box>
          <Divider my={6} sx={{ margin: '12px 0 0' }} />
        </div>
        {loadingCell === 0 ? (
          <Box sx={{ width: '22px', height: '50px', margin: '50px auto 0' }}>
            <CircularProgress sx={{ width: '100%', height: '100%' }} />
          </Box>
        ) : (
          <div style={{ height: '100%', overflow: 'auto' }}>
            {isDesktop ? (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow sx={{ fontSize: '10px' }}>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          fontWeight: 400,
                          position: 'sticky',
                          top: 0,
                          left: isDesktop ? 0 : 'auto',
                          zIndex: 2,
                          background: 'white',
                        }}>
                        Företag
                      </TableCell>

                      <SortingTableCell
                        title="Live datum"
                        fieldName="agreement_start_date"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Uppsägningsdatum"
                        fieldName="notice_date"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Slutdatum"
                        fieldName="agreement_end_date"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Förnyelsedatum"
                        fieldName="renewal_date"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Revenue per month"
                        fieldName="revenue_per_month"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Revenue"
                        fieldName="total_revenue"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Total leads"
                        fieldName="total_leads"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Förväntan"
                        fieldName="company_leads_expectation_id"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Leads 30 dagar"
                        fieldName="total_leads_last_30_days"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <SortingTableCell
                        title="Antal sidor"
                        fieldName="total_sites"
                        sort={sort}
                        reverseSort={reverseSort}
                        onClick={changeSort}
                        style={headerTableCellStickyStyle}
                      />
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          width: '200px',
                          fontWeight: 400,
                          ...headerTableCellStickyStyle,
                        }}
                        align="right">
                        Säljare
                      </TableCell>
                      <TableCell sx={headerTableCellStickyStyle}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientCompaniesList?.map((row) => (
                      <Fragment key={row.id}>
                        <TableRow
                          sx={{
                            cursor: 'pointer',
                            background:
                              row.payer_status === PAYER_STATUS.BadPayer ? '#ffdedc' : 'white',
                          }}
                          onClick={() => clickHandler(row.id)}>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              fontWeight: 700,
                              position: isDesktop ? 'sticky' : 'static',
                              left: 0,
                              background:
                                row.payer_status === PAYER_STATUS.BadPayer ? '#ffdedc' : 'white',
                              zIndex: 1,
                              color: row.payer_status === PAYER_STATUS.BadPayer ? 'red' : 'inherit',
                            }}
                            onClick={
                              row.payer_status !== PAYER_STATUS.GoodPayer
                                ? (e) => {
                                    e.stopPropagation();
                                    setOpenImmunityCalender(row.id);
                                  }
                                : undefined
                            }>
                            <span style={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
                              <div>
                                {row.name || ''}{' '}
                                {row.subscriptions?.length > 1 && (
                                  <sup style={{ color: 'red' }}>{row.subscriptions.length}</sup>
                                )}
                              </div>
                              {row.payer_status === PAYER_STATUS.Immunity && (
                                <InfoIconWithTooltip
                                  title={`Immunity until ${
                                    row.unpaid_invoice_immunity.split('T')[0]
                                  }`}
                                />
                              )}
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              textAlign: 'end',
                            }}>
                            {row.agreement_start_date?.slice(0, 10) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              textAlign: 'end',
                            }}>
                            {row.notice_date?.slice(0, 10) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              textAlign: 'end',
                            }}>
                            {row.agreement_end_date?.slice(0, 10) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              textAlign: 'end',
                            }}>
                            {row.renewal_date?.slice(0, 10) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'end',
                            }}>
                            {row.revenue_per_month?.toFixed(0) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'end',
                            }}>
                            {row.total_revenue?.toFixed(0) || ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'end',
                            }}>
                            {row.total_leads || 0}
                          </TableCell>
                          {updateLoader && openSelect === row.id ? (
                            <TableCell
                              sx={{
                                textAlign: 'end',
                              }}>
                              Updating...
                            </TableCell>
                          ) : (
                            <TableCell
                              onClick={(e) => {
                                setAnchorEl(e.target);
                                setOpenSelect(row.id);
                                e.stopPropagation();
                              }}
                              sx={{
                                textAlign: 'end',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                              }}>
                              {row.company_leads_expectation_id
                                ? LEADS_EXPECTATION_NAMES[row.company_leads_expectation_id]
                                : ''}
                            </TableCell>
                          )}
                          <TableCell
                            sx={{
                              textAlign: 'end',
                            }}>
                            {row.total_leads_last_30_days}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: 'end',
                            }}>
                            {row.total_sites || 0}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: 'nowrap',
                              textAlign: 'end',
                            }}>
                            {row.subscriptions[0]?.salesperson_id
                              ? getSalespersonNamesString(row.subscriptions[0].salesperson_id)
                              : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            <Tooltip title="Kundvy" arrow>
                              <IconButton
                                size="small"
                                onClick={(event) => openUserView({ event, companyName: row.name })}>
                                <PersonIcon
                                  sx={{
                                    cursor: 'pointer',
                                    height: '18px',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <LeadExpectationMenu
                            key={row.id}
                            open={openSelect}
                            onClose={onCloseLeadExpectationMenu}
                            onChange={(e) => onChangeLeadsExpectation(e)}
                            anchorEl={anchorEl}
                          />
                        </TableRow>
                        {row.subscriptions?.length > 0 && (
                          <>
                            {expandedId === row.id &&
                              row.subscriptions.length > 1 &&
                              row.subscriptions.map((sub) => (
                                <CompanyTableRowWithOptimization
                                  data={sub}
                                  getSalespersonNamesString={getSalespersonNamesString}
                                  isDesktop={isDesktop}
                                />
                              ))}

                            <TableRow
                              sx={{
                                borderBottom: expandedId === row.id && '1px solid #e0e0e0',
                                backgroundColor: '#fff',
                              }}>
                              <TableCell
                                style={{
                                  position: isDesktop ? 'sticky' : 'static',
                                  left: 0,
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  borderTop: 'white',

                                  borderWidth: 0,
                                }}
                                colSpan={5}>
                                <Collapse in={expandedId === row.id} timeout="auto" unmountOnExit>
                                  <CompanySitesTable companyName={row.name} />
                                  <TextAreaWithSignatureButton
                                    optimization={row.optimization}
                                    changeComment={changeComment}
                                    newComment={newComment}
                                    setNewComment={setNewComment}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}>
                                    <Alert
                                      sx={{ margin: '15px 0', fontSize: '12px', lineHeight: 1 }}
                                      icon={false}
                                      variant="filled"
                                      severity={row.optimization?.id ? 'warning' : 'success'}>
                                      {row.optimization?.id
                                        ? `Optimization stage: ${getKeyObjectByValue({
                                            object: OPTIMIZATION_STAGE,
                                            value: row.optimization?.stage,
                                          })}`
                                        : 'This company does not need optimization'}
                                    </Alert>
                                    <ChangeOptimizationStageButton
                                      disabled={loadingCell}
                                      stage={row.optimization?.stage || null}
                                      onChange={onChange}
                                      optimizationId={row.optimization?.id || null}
                                      companyId={row.id}
                                    />
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {clientCompaniesList?.map((row) => (
                  <CompanyCard
                    onClick={() => clickHandler(row.id)}
                    data={row}
                    getSalespersonNamesString={getSalespersonNamesString}
                    openUserView={openUserView}
                    onChange={onChange}
                    loadingCell={loadingCell}
                    changeComment={changeComment}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    newComment={newComment}
                    setNewComment={setNewComment}
                    setOpenImmunityCalender={setOpenImmunityCalender}
                  />
                ))}
              </div>
            )}
            <CalendarPopup
              open={openImmunityCalender}
              onClose={() => setOpenImmunityCalender(null)}
              onDateSelect={changeImmunityDate}
              defaultValue={
                clientCompaniesList.find((company) => company.id === openImmunityCalender)
                  ?.unpaid_invoice_immunity || null
              }
              title="Immunity date"
            />
          </div>
        )}
        {count && (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ClientsCompaniesList;
