import axios from '../utils/axios';

export const getAllInvoices = async ({ page, limit, ...otherFields }) => {
  try {
    const query = Object.entries({ page, limit, ...otherFields })
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    const invoices = await axios.get(`/api/my-pages/invoices?${query}`);
    return invoices.data;
  } catch (error) {
    return error;
  }
};

export const getInvoice = async ({ id }) => {
  try {
    const response = await axios.get(`/api/my-pages/invoices/${id}/preview`, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getInvoicesSalesStatistic = async () => {
  try {
    const invoices = await axios.get('/api/my-pages/invoices/invoicesSalesStatistic');
    return invoices.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
