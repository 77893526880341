import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useInvoiceTableNavigation = ({ page, limit, sort, order, handlePageChange }) => {
  const [salesperson, setSalesperson] = useState(0);
  const [company, setCompany] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitialFilterValues = () => ({
    page: parseInt(searchParams.get('page'), 10) || 1,
    limit: parseInt(searchParams.get('limit'), 10) || 20,
    sort: searchParams.get('sort') || '',
    order: searchParams.get('order') || 'DESC',
    salesperson: parseInt(searchParams.get('salesperson_id'), 10) || 0,
    company: searchParams.get('customer_name') || '',
  });

  useEffect(() => {
    const updatedParams = { ...searchParams };

    if (page) updatedParams.page = page;
    if (limit) updatedParams.limit = limit;
    if (sort) updatedParams.sort = sort;
    if (order) updatedParams.order = order;
    if (salesperson) updatedParams.salesperson_id = salesperson;
    if (company) updatedParams.customer_name = company;

    setSearchParams(updatedParams);
  }, [page, limit, sort, order, salesperson, company]);

  const handleSalespersonChange = (value) => {
    handlePageChange(0);
    setSalesperson(value);
  };

  const handleCompanyChange = (value) => {
    handlePageChange(0);
    setCompany(value);
  };
  return {
    ...getInitialFilterValues(),
    handleSalespersonChange,
    handleCompanyChange,
  };
};
