import { PAYER_STATUS } from '../constants/payerStatus';

export const getPayerStatuses = (companies) =>
  companies.map((company) => {
    const companyData = company.company || company;
    const dueDate = companyData.unpaid_invoice_due_date
      ? new Date(companyData.unpaid_invoice_due_date).getTime()
      : null;
    const immunityDate = companyData.unpaid_invoice_immunity
      ? new Date(companyData.unpaid_invoice_immunity).getTime()
      : null;
    const now = Date.now();

    let payerStatus = PAYER_STATUS.GoodPayer;

    if (dueDate && dueDate < now) {
      if (immunityDate && immunityDate > now) {
        payerStatus = PAYER_STATUS.Immunity;
      } else {
        payerStatus = PAYER_STATUS.BadPayer;
      }
    }

    return { ...company, payer_status: payerStatus };
  });
