import { memo, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import { fetchOptimizationLogs } from '../../API/optimizationApi';
import { OPTIMIZATION_STAGE, OPTIMIZATION_STAGE_NAMES } from '../../constants/optimizationsStage';

const OptimizationHistory = ({ optimizationId }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const loadLogs = async () => {
      const fetchedLogs = await fetchOptimizationLogs(optimizationId);
      setLogs(fetchedLogs);
    };

    if (optimizationId) {
      loadLogs();
    }
  }, [optimizationId]);

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom align="center">
        Optimization History
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Changed At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.length ? (
            logs.map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  {log.user.firstName} {log.user.lastName}
                </TableCell>
                <TableCell>
                  {+log.new_value === OPTIMIZATION_STAGE.PENDING &&
                  +log.old_value === OPTIMIZATION_STAGE.PENDING
                    ? `Created with ${log.field_name} ${OPTIMIZATION_STAGE_NAMES[log.new_value]}`
                    : ` Changed ${log.field_name} from ${
                        OPTIMIZATION_STAGE_NAMES[log.old_value]
                      } to ${OPTIMIZATION_STAGE_NAMES[log.new_value]}`}
                </TableCell>
                <TableCell>{new Date(log.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No history available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(OptimizationHistory);
