import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Stats from '../../pages/dashboards/Default/Stats';
import { getInvoicesSalesStatistic } from '../../API/invoiceApi';
import { useSalespersons } from '../../hooks/useSalespersons';
import { getSalespersonFullName } from '../../utils/getSalespersonFullName';
import { formatNumber } from '../../utils/formaterNumber';

const StatsCard = () => {
  const { salespersons, loading: salespersonsLoading } = useSalespersons();
  const { data: count = {}, isLoading } = useQuery(['count'], () => getInvoicesSalesStatistic(), {
    keepPreviousData: true,
  });
  if (isLoading || salespersonsLoading) return null;
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
        <Stats
          title={<div style={{ paddingRight: 60 }}>Total </div>}
          amount={
            <span style={{ whiteSpace: 'nowrap', fontSize: '22px' }}>
              {formatNumber(count.total.count) || 0} / {formatNumber(count.total.total) || 0} kr
            </span>
          }
          chip="Annual"
        />
      </Grid>
      {count.bySalesperson.map((salesperson) => (
        <Grid key={salesperson.salesperson_id} item xs={12} sm={6} md={4} lg={3} xl={2.4}>
          <Stats
            title={
              <div style={{ paddingRight: 60 }}>
                {getSalespersonFullName(salespersons, salesperson.salesperson_id, false)}
              </div>
            }
            amount={
              <span style={{ whiteSpace: 'nowrap', fontSize: '22px' }}>
                {formatNumber(salesperson.count) || 0} / {formatNumber(salesperson.total) || 0} kr{' '}
              </span>
            }
            chip="Annual"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsCard;
