import { TextField } from '@mui/material';
import SignatureButton from '../SignatureButton';
import useTextAreaWithSignatureButton from '../../hooks/useTextAreaWithSignatureButton';

const TextAreaWithSignatureButton = ({
  optimization,
  changeComment,
  newComment,
  setNewComment,
}) => {
  const { commentValue, handleInputChange, addSignature, textareaRef } =
    useTextAreaWithSignatureButton({
      optimization,
      changeComment,
      newComment,
      setNewComment,
    });
  return (
    <>
      <SignatureButton onClick={() => addSignature()} />
      <TextField
        inputRef={textareaRef}
        multiline
        sx={{
          width: '100%',
          '& .MuiInputBase-multiline': {
            padding: '8px',
          },
          '& .MuiInputBase-input': {
            fontSize: '12px',
          },
        }}
        minRows={3}
        maxRows={8}
        size="sm"
        variant="outlined"
        value={commentValue}
        name="notes"
        onChange={handleInputChange}
      />
    </>
  );
};

export default TextAreaWithSignatureButton;
