import { Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { LEADS_EXPECTATION_NAMES } from '../../constants/leadsExpectationNames';
import CompanyCardModal from './CompanyCardModal';
import { PAYER_STATUS } from '../../constants/payerStatus';
import InfoIconWithTooltip from '../InfoIconWithTooltip';

const CompanyCard = ({
  data,
  getSalespersonNamesString,
  openUserView,
  onClick,
  onChange,
  loadingCell,
  changeComment,
  expandedId,
  setExpandedId,
  newComment,
  setNewComment,
  setOpenImmunityCalender,
}) => (
  <>
    <Card
      onClick={onClick}
      variant="outlined"
      sx={{
        background:
          data.payer_status === PAYER_STATUS.BadPayer
            ? 'linear-gradient(to bottom, #ffdedc 40px, white 40px)'
            : 'white',
      }}>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          zIndex: 1,
        }}>
        <div>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 16,
              cursor: data.payer_status !== PAYER_STATUS.GoodPayer ? 'pointer' : 'inherit',
            }}
            onClick={
              data.payer_status !== PAYER_STATUS.GoodPayer
                ? (e) => {
                    e.stopPropagation();
                    setOpenImmunityCalender(data.id);
                  }
                : undefined
            }>
            {data.name}
            {data.payer_status === PAYER_STATUS.Immunity && (
              <InfoIconWithTooltip
                title={`Immunity until ${data.unpaid_invoice_immunity.split('T')[0]}`}
              />
            )}
          </Typography>
          <Typography>Live datum: {data.agreement_start_date?.slice(0, 10) || '-'}</Typography>
          <Typography>Uppsägningsdatum: {data.notice_date?.slice(0, 10) || '-'}</Typography>
          <Typography>Slutdatum: {data.agreement_end_date?.slice(0, 10) || '-'}</Typography>
          <Typography>Förnyelsedatum: {data.renewal_date?.slice(0, 10) || '-'}</Typography>
          <Typography>Revenue: {data.total_revenue?.toFixed(0) || '-'}</Typography>
          <Typography>Total leads: {data.total_leads || 0}</Typography>
          <Typography>
            Förväntan:{' '}
            {data.company_leads_expectation_id
              ? LEADS_EXPECTATION_NAMES[data.company_leads_expectation_id]
              : '-'}
          </Typography>
          <Typography>Leads 30 dagar: {data.total_leads_last_30_days}</Typography>
          <Typography>Antal sidor: {data.total_sites || 0}</Typography>
          <Typography>
            Säljare:{' '}
            {data.subscriptions[0]?.salesperson_id
              ? getSalespersonNamesString(data.subscriptions[0].salesperson_id)
              : '-'}
          </Typography>
        </div>
        <Tooltip title="Kundvy" arrow>
          <IconButton
            sx={{ p: 0 }}
            size="small"
            onClick={(event) => openUserView({ event, companyName: data.name })}>
            <PersonIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </IconButton>
        </Tooltip>
      </CardContent>
    </Card>
    <CompanyCardModal
      open={expandedId === data.id}
      onClose={() => setExpandedId(null)}
      getSalespersonNamesString={getSalespersonNamesString}
      changeComment={changeComment}
      loadingCell={loadingCell}
      onChange={onChange}
      data={data}
      newComment={newComment}
      setNewComment={setNewComment}
    />
  </>
);

export default CompanyCard;
