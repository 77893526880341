import { getUserById } from '../API/usersApi';
import { formatDate } from './dateFormatter';
import { isUser } from './jwt';

export const createSignature = async () => {
  const { id } = isUser();
  const date = formatDate(new Date());
  const user = await getUserById(id);
  let signature = user.email;
  if (user.firstName || user.lastName) {
    signature = `${user.firstName || ''} ${user.lastName || ''}`;
  }
  signature += ` ${date}:`;
  return signature;
};
