import axios from '../utils/axios';

export const getSiteLeads = async ({ page, limit, siteId, companyName, keyword, withClient }) => {
  const decodedCompanyName = companyName?.replaceAll('&', '%26');
  let query = '';
  try {
    if (page !== 0) {
      query += `page=${page}`;
    }
    if (limit !== 20) {
      query += `&limit=${limit}`;
    }
    if (siteId) {
      query += `&siteId=${siteId}`;
    }
    if (decodedCompanyName) {
      query += `&companyName=${decodedCompanyName}`;
    }
    if (keyword) {
      query += `&keywords=${keyword}`;
    }

    if (withClient !== undefined) {
      query += `&withClient=${withClient}`;
    }

    const response = await axios.get(`/api/my-pages/emails?${query}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getAllMyLeads = async ({ page = 0, limit = 20, search }) => {
  let query = '';

  if (page !== 0) {
    query += `page=${page}`;
  }
  if (limit !== 20) {
    query += `&limit=${limit}`;
  }
  if (search) {
    query += `&search=${search}`;
  }
  return axios.get(`/api/my-pages/emails/my?${query}`);
};

export const setLeadsNotNew = async (leadsIds, setNew = false) => {
  try {
    await axios.post('/api/my-pages/emails/set-not-new', { leadsIds, setNew });
  } catch (error) {
    console.log(error);
  }
};

export const getLead = async (leadsId) => {
  try {
    const lead = await axios.get(`/api/my-pages/emails/${leadsId}`);
    return lead;
  } catch (error) {
    return error;
  }
};

export const deleteLead = async (leadsId) => {
  try {
    await axios.delete(`/api/my-pages/emails?leadId=${leadsId}`);
  } catch (error) {
    console.log(error);
  }
};

export const countSiteLeads = async (siteId, ownerId) => {
  const query = ownerId ? `&ownerId=${ownerId}` : '';
  try {
    const response = await axios.get(`/api/my-pages/emails/count-site?siteId=${siteId}${query}`);
    return response;
  } catch (error) {
    return console.log(error);
  }
};
