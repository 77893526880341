import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

const InfoIconWithTooltip = ({ title }) => (
  <Tooltip title={title} arrow>
    <InfoIcon
      sx={{
        fontSize: '16px',
        color: 'red',
        marginLeft: '8px',
        cursor: 'pointer',
      }}
    />
  </Tooltip>
);

export default InfoIconWithTooltip;
