import { useEffect, useState } from 'react';

import { Grid, TablePagination, Typography } from '@mui/material';

import InfoDownloadFile from '../InfoDownloadFile';
import { getMyFiles } from '../../API/usersApi';
import ImageCard from '../ImageCard';

const InfoPage = () => {
  const [myFiles, setMyFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const getFiles = async () => {
    try {
      const { data } = await getMyFiles(page, limit);
      setMyFiles(data.rows);
      setCount(data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFiles();
  }, [page, limit]);

  return (
    <>
      <InfoDownloadFile onChange={() => getFiles()} />
      {!myFiles.length ? (
        <Typography sx={{ padding: '20px 0' }} variant="h3">
          You don`t have any downloaded files
        </Typography>
      ) : (
        <>
          <Typography sx={{ padding: '20px 0' }} variant="h2">
            Image List
          </Typography>
          <Grid container spacing={6}>
            {myFiles.map((file) => (
              <ImageCard key={file.link} link={file.link} onChange={() => getFiles()} />
            ))}
          </Grid>
          {count && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfoPage;
