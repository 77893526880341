import { Grid } from '@mui/material';
import InvoicesTable from './invoiceTable/InvoicesTable';

const ClientInvoicePage = () => (
  <Grid sx={{ padding: '20px' }}>
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <InvoicesTable />
      </Grid>
    </Grid>
  </Grid>
);

export default ClientInvoicePage;
