import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { getOptimizationList } from '../../API/companyApi';
import { OPTIMIZATION_STAGE } from '../../constants/optimizationsStage';
import { GlobalContext } from '../../contexts/GlobalContext';
import { getPayerStatuses } from '../../utils/getPayerStatus';

const useOptimizationClientList = () => {
  const { setOptimizationListAmounts } = useContext(GlobalContext);
  const { status } = useParams();
  const [companyList, setCompanyList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [expandedId, setExpandedId] = useState(null);
  const [loadingCell, setLoadingCell] = useState(null);
  const [stage, setStage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setExpandedId(null);
    if (status && status !== 'all') {
      setStage(OPTIMIZATION_STAGE[status.toUpperCase()]);
    }
    if (status === 'all') {
      setStage(OPTIMIZATION_STAGE.PENDING);
    }
  }, [status]);

  const isDesktop = useMediaQuery('(min-width:600px)');
  const updateList = async () => {
    setLoadingCell(0);
    if (stage !== null) {
      const data = await getOptimizationList({
        limit,
        page,
        stage,
      });
      const updatedCompanies = getPayerStatuses(data.optimizations);
      setOptimizationListAmounts(data.stageCounts);
      setCount(data.totalOptimizationsCount);
      setCompanyList(updatedCompanies);
    }
    setLoadingCell(null);
  };

  useEffect(() => {
    updateList();
  }, [page, limit, stage]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeLimit = useCallback((e) => {
    setPage(0);
    setLimit(e.target.value);
  }, []);

  const clickHandler = useCallback((id) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  });

  const changeComment = useCallback((id, comment) => {
    setCompanyList((prevList) =>
      prevList.map((company) => (company.id === id ? { ...company, comment } : company)),
    );
  }, []);

  const changeStage = useCallback(
    (id, newStage) => {
      if (stage !== newStage) {
        setOpenSnackbar(true);
        updateList();
      }
    },
    [stage],
  );

  return {
    companyList,
    page,
    count,
    limit,
    expandedId,
    loadingCell,
    isDesktop,
    setCompanyList,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
    changeComment,
    changeStage,
    openSnackbar,
    setOpenSnackbar,
  };
};

export default useOptimizationClientList;
