import { useCallback, useEffect, useRef, useState } from 'react';
import { putOptimization } from '../API/companyApi';
import { debounce } from '../utils/debounce';
import { createSignature } from '../utils/createSignature';

const useTextAreaWithSignatureButton = ({
  optimization,
  changeComment,
  newComment,
  setNewComment,
}) => {
  const [commentValue, setCommentValue] = useState(optimization?.comment || newComment || '');
  const textareaRef = useRef();

  const debouncedPutOptimization = useCallback(
    debounce((value) => {
      if (optimization?.id) {
        putOptimization({ id: optimization.id, comment: value || '' });
      }
    }, 3000),
    [optimization?.id],
  );

  useEffect(() => {
    setCommentValue(optimization?.comment || newComment || '');
  }, [optimization?.comment, newComment]);

  const handleInputChange = useCallback(
    (e) => {
      const newNotes = e.target.value;
      setCommentValue(newNotes);

      if (optimization?.id) {
        changeComment(optimization.id, newNotes);
        debouncedPutOptimization(newNotes);
      } else {
        setNewComment(newNotes);
      }
    },
    [optimization?.id, changeComment, debouncedPutOptimization, setNewComment],
  );

  const addSignature = useCallback(async () => {
    const signature = await createSignature();
    const updatedComment = commentValue ? `${commentValue}\n${signature}` : signature;

    setCommentValue(updatedComment);

    if (optimization?.id) {
      changeComment(optimization.id, updatedComment);
    } else {
      setNewComment(updatedComment);
    }

    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(updatedComment.length, updatedComment.length);
    }
  }, [commentValue, optimization?.id, changeComment, setNewComment]);

  return { commentValue, handleInputChange, addSignature, textareaRef };
};

export default useTextAreaWithSignatureButton;
