import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { INVOICE_TABLE_HEAD_CELLS } from '../../constants/invoiceConstant';

const InvoiceTableHead = ({ setSort, sort, order, role }) => (
  <TableHead>
    <TableRow>
      {INVOICE_TABLE_HEAD_CELLS.map((headCell) => {
        if (role === 'client' && headCell.name === 'Company') {
          return null;
        }
        return (
          <TableCell key={headCell.name} align={headCell.alignment}>
            {headCell.sortId ? (
              <TableSortLabel
                sx={{ cursor: 'pointer' }}
                active={sort === headCell.sortId}
                direction={order.toLowerCase()}
                onClick={() => setSort(headCell.sortId)}>
                {headCell.name}
              </TableSortLabel>
            ) : (
              headCell.name
            )}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

export default InvoiceTableHead;
