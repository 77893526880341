import { useContext } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Loader from '../../Loader';
import { getLeadsExpectationChartData, getLeadsPerMonthChartData } from '../../../API/dashboardApi';
import LeadExpectationChart from './LeadExpectationChart';
import LeadsPerMonthChart from './LeadsPerMonthChart';
import { LeadsDashboardContext } from '../../../contexts/dashboard/LeadsDashboardContext';

const LeadExpectationPage = () => {
  const {
    dates: { startDate, endDate },
  } = useContext(LeadsDashboardContext);
  const { data: stats, isLoading } = useQuery({
    queryKey: ['leadsExpectationChartData'],
    queryFn: () => getLeadsExpectationChartData(),
    staleTime: 60000,
  });

  const { data: leadsPerMonth, isLoading: isLoadingLeads } = useQuery({
    queryKey: ['leadsPerMonthChartData', startDate, endDate],
    queryFn: () => getLeadsPerMonthChartData({ startDate, endDate }),
    staleTime: 60000,
  });

  if (isLoading || isLoadingLeads) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <LeadExpectationChart data={stats} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LeadsPerMonthChart data={leadsPerMonth} />
      </Grid>
    </Grid>
  );
};

export default LeadExpectationPage;
