import { createContext, useMemo, useState } from 'react';
import { generateMonthOptions } from '../../utils/dateFormatter';

const LeadsDashboardContext = createContext(null);

const LeadsContextDashboardProvider = ({ children }) => {
  const [dates, setDate] = useState(generateMonthOptions()[0]);
  const value = useMemo(
    () => ({
      dates,
      setDate,
    }),
    [dates],
  );

  return <LeadsDashboardContext.Provider value={value}>{children}</LeadsDashboardContext.Provider>;
};

export { LeadsDashboardContext, LeadsContextDashboardProvider };
