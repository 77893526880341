import { Button } from '@mui/material';

const SignatureButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    size="small"
    variant="text"
    sx={{
      float: 'right',
      fontSize: '10px',
      padding: '4px 8px',
    }}>
    Infoga signature
  </Button>
);

export default SignatureButton;
