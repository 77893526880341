import React from 'react';
import { Navigate } from 'react-router-dom';
import PageWrapper from './components/PageWrapper';

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Auth components
import SignIn from './pages/auth/SignIn';
import RestorePassword from './pages/auth/RestorePassword';
import NewPassword from './pages/auth/NewPassword';
import ConfirmInvite from './pages/auth/ConfirmInvite';
import Page404 from './pages/auth/Page404';

// Protected routes
import AdminGuard from './components/guards/AdminGuard';
import UsersList from './components/AdminPage/UsersList';
import SitesList from './components/AdminPage/SitesList';
import DomainPage from './components/UserPage/DomainPage';
import LeadsPage from './components/UserPage/LeadsPage';
import LeadsList from './components/AdminPage/LeadsList';
import DashboardPage from './components/UserPage/DashboardPage';
import AdminDashboardPage from './components/AdminPage/AdminDashboardPage';
import RankingPage from './components/AdminPage/RankingPage';
import UserStatisticsPage from './components/AdminPage/UserStatisticsPage';
import UserSettingsPage from './components/UserPage/UserSettingsPage';
// import SalespersonDashboardPage from './components/SalespersonPage/SalespersonDashbordPage';
import RankingDashboardPage from './components/SalespersonPage/RankingDashboardPage';
import AdminClientList from './components/AdminPage/AdminClientList';
import AssignSiteOwner from './components/AdminPage/AssignSiteOwner';
import InfoPage from './components/AdminPage/InfoPage';
import InfoForm from './components/AdminPage/InfoForm';
import LeadPage from './components/UserPage/LeadPage';
import { LeadsContextProvider } from './contexts/LeadsContext';
import OptimizationClientList from './components/AdminPage/OptimizationClientList';
import ClientsCompaniesList from './components/AdminPage/ClientsCompaniesList';
import PromotionsListContainer from './containers/proposals/ProposalsListContainer';
import ProposalPage from './containers/proposals/ProposalPage';
import AdminInvoicesPage from './components/AdminPage/AdminInvoicesPage';
import ClientInvoicePage from './components/ClientInvoicePage';
import LeadExpectationPage from './components/AdminPage/LeadsDashboard/LeadExpectationPage';
import { LeadsContextDashboardProvider } from './contexts/dashboard/LeadsDashboardContext';

const routes = [
  {
    path: '/',
    element: <Navigate to="/auth/sign-in" />,
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: (
          <PageWrapper title="Sign In">
            <SignIn />
          </PageWrapper>
        ),
      },
      {
        path: 'restore-password',
        element: (
          <PageWrapper title="Restore Password">
            <RestorePassword />
          </PageWrapper>
        ),
      },
      {
        path: 'restore-new-password',
        element: (
          <PageWrapper title="New Password">
            <NewPassword />
          </PageWrapper>
        ),
      },
      {
        path: 'invite-confirmation',
        element: (
          <PageWrapper title="Confirm Invite">
            <ConfirmInvite />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: 'client',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/client', element: <Navigate to="/client/dashboard" /> },
      {
        path: 'dashboard',
        element: (
          <PageWrapper logo="true" title="Dashboard">
            <DashboardPage />
          </PageWrapper>
        ),
      },
      {
        path: 'pages',
        element: (
          <PageWrapper logo="true" title="Sidor">
            <DomainPage />
          </PageWrapper>
        ),
      },
      {
        path: 'invoices',
        element: (
          <PageWrapper logo="true" title="Fakturor page">
            <ClientInvoicePage />
          </PageWrapper>
        ),
      },
      {
        path: 'leads',
        element: (
          <LeadsContextProvider>
            <PageWrapper logo="true" title="Kundförfrågningar">
              <LeadsPage />
            </PageWrapper>
          </LeadsContextProvider>
        ),
      },
      {
        path: 'leads/:id',
        element: (
          <PageWrapper logo="true" title="Kundförfrågningar">
            <LeadPage />
          </PageWrapper>
        ),
      },
      {
        path: 'settings',
        element: (
          <PageWrapper logo="true" title="Info">
            <UserSettingsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'info',
        children: [
          {
            path: 'upload',
            element: (
              <PageWrapper logo="true" title="Upload images">
                <InfoPage />
              </PageWrapper>
            ),
          },
          {
            path: 'form',
            element: (
              <PageWrapper logo="true" title="Form">
                <InfoForm />
              </PageWrapper>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'salesperson',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/salesperson', element: <Navigate to="/salesperson/dashboard/default" /> },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: (
              <PageWrapper logo="true" title="Default">
                {/* <SalespersonDashboardPage /> */}
                <AdminDashboardPage />
              </PageWrapper>
            ),
          },
          {
            path: 'ranking',
            element: (
              <PageWrapper logo="true" title="Ranking">
                <RankingDashboardPage />
              </PageWrapper>
            ),
          },
        ],
      },
      {
        path: 'sites',
        element: (
          <PageWrapper logo="true" title="Sidor">
            <DomainPage />
          </PageWrapper>
        ),
      },
      {
        path: 'client',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder">
            <AdminClientList />
          </PageWrapper>
        ),
      },
      {
        path: 'client/:id',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
      {
        path: 'kundföretag',
        element: (
          <PageWrapper logo="true" title="Kundföretag">
            <ClientsCompaniesList />
          </PageWrapper>
        ),
      },
      {
        path: 'settings',
        element: (
          <PageWrapper logo="true" title="Inställningar">
            <UserSettingsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'leads',
        element: (
          <PageWrapper logo="true" title="Kundförfrågningar">
            <LeadsList />
          </PageWrapper>
        ),
      },
      {
        path: 'users',
        element: (
          <PageWrapper logo="true" title="Användare">
            <UsersList />
          </PageWrapper>
        ),
      },
      {
        path: 'users/:id',
        element: (
          <PageWrapper logo="true" title="Användare">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
      {
        path: 'proposal',
        element: (
          <PageWrapper logo="true" title="Proposals">
            <PromotionsListContainer />
          </PageWrapper>
        ),
      },
      {
        path: 'proposal/:id',
        element: (
          <PageWrapper logo="true" title="Proposals page">
            <ProposalPage />
          </PageWrapper>
        ),
      },
      {
        path: 'invoices',
        element: (
          <PageWrapper logo="true" title="Fakturor page">
            <AdminInvoicesPage />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      { path: '/admin', element: <Navigate to="/admin/dashboard/default" /> },
      {
        path: 'dashboard',

        children: [
          {
            path: 'default',
            element: (
              <PageWrapper logo="true" title="Default">
                <AdminDashboardPage />
              </PageWrapper>
            ),
          },
          {
            path: 'ranking',
            element: (
              <PageWrapper logo="true" title="Ranking">
                <RankingPage />
              </PageWrapper>
            ),
          },
          {
            path: 'user-statistics',
            element: (
              <PageWrapper logo="true" title="User statistics">
                <UserStatisticsPage />
              </PageWrapper>
            ),
          },
          {
            path: 'leads',
            element: (
              <PageWrapper logo="true" title="Leads">
                <LeadsContextDashboardProvider>
                  <LeadExpectationPage />
                </LeadsContextDashboardProvider>
              </PageWrapper>
            ),
          },
        ],
      },
      {
        path: 'sites',
        element: (
          <PageWrapper logo="true" title="Sidor">
            <SitesList />
          </PageWrapper>
        ),
      },
      {
        path: 'client',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder" bgColor="#FFFFFF">
            <AdminClientList />
          </PageWrapper>
        ),
      },
      {
        path: 'client/:id',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
      {
        path: 'kundföretag',
        element: (
          <PageWrapper logo="true" title="Kundföretag">
            <ClientsCompaniesList />
          </PageWrapper>
        ),
      },
      {
        path: 'leads',
        element: (
          <PageWrapper logo="true" title="Kundförfrågningar">
            <LeadsList />
          </PageWrapper>
        ),
      },
      {
        path: 'settings',
        element: (
          <PageWrapper logo="true" title="Inställningar">
            <UserSettingsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'users',
        element: (
          <PageWrapper logo="true" title="Användare">
            <UsersList />
          </PageWrapper>
        ),
      },
      {
        path: 'users/:id',
        element: (
          <PageWrapper logo="true" title="Användare">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
      {
        path: 'optimering',
        children: [
          {
            path: ':status',
            element: (
              <PageWrapper logo="true" title="Optimering" bgColor="#FFFFFF">
                <OptimizationClientList />
              </PageWrapper>
            ),
          },
        ],
      },
      {
        path: 'proposal',
        element: (
          <PageWrapper logo="true" title="Proposals">
            <PromotionsListContainer />
          </PageWrapper>
        ),
      },
      {
        path: 'proposal/:id',
        element: (
          <PageWrapper logo="true" title="Proposals page">
            <ProposalPage />
          </PageWrapper>
        ),
      },
      {
        path: 'invoices',
        element: (
          <PageWrapper logo="true" title="Fakturor page">
            <AdminInvoicesPage />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/user', element: <Navigate to="/user/dashboard/default" /> },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: (
              <PageWrapper logo="true" title="Default">
                <AdminDashboardPage />
              </PageWrapper>
            ),
          },
          {
            path: 'ranking',
            element: (
              <PageWrapper logo="true" title="Ranking">
                <RankingPage />
              </PageWrapper>
            ),
          },
          {
            path: 'user-statistics',
            element: (
              <PageWrapper logo="true" title="User statistics">
                <UserStatisticsPage />
              </PageWrapper>
            ),
          },
        ],
      },
      {
        path: 'sites',
        element: (
          <PageWrapper logo="true" title="Sidor">
            <SitesList />
          </PageWrapper>
        ),
      },
      {
        path: 'client',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder">
            <AdminClientList />
          </PageWrapper>
        ),
      },
      {
        path: 'client/:id',
        element: (
          <PageWrapper logo="true" title="Inlogg kunder">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
      {
        path: 'kundföretag',
        element: (
          <PageWrapper logo="true" title="Kundföretag">
            <ClientsCompaniesList />
          </PageWrapper>
        ),
      },
      {
        path: 'leads',
        element: (
          <PageWrapper logo="true" title="Kundförfrågningar">
            <LeadsList />
          </PageWrapper>
        ),
      },
      {
        path: 'settings',
        element: (
          <PageWrapper logo="true" title="Inställningar">
            <UserSettingsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'users',
        element: (
          <PageWrapper logo="true" title="Användare">
            <UsersList />
          </PageWrapper>
        ),
      },
      {
        path: 'users/:id',
        element: (
          <PageWrapper logo="true" title="Användare">
            <AssignSiteOwner />
          </PageWrapper>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: (
          <PageWrapper title="404">
            <Page404 />
          </PageWrapper>
        ),
      },
    ],
  },
];

export default routes;
