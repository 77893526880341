import {
  Paper,
  Table,
  TableContainer,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAllInvoices } from '../../API/invoiceApi';
import InvoiceTableHead from './InvoiceTableHead';
import { usePagination } from '../../hooks/table/usePagination';
import Loader from '../Loader';
import InvoicesTableBody from './InvoicesTableBody';
import { useSorting } from '../../hooks/table/useSort';
import SalespersonSelect from '../SalespersonSelect';
import { useSalespersons } from '../../hooks/useSalespersons';
import useAuth from '../../hooks/useAuth';
import { useDownloadInvoice } from '../../hooks/Invoice/useDownloadInvoice';
import ChooseLeadsCompanyName from '../AdminPage/ChooseLeadsCompanyName';
import { useInvoiceTableNavigation } from '../../hooks/Invoice/useInvoiceTableNavigation';

const InvoicesTable = () => {
  const { salespersons } = useSalespersons();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const { userMe } = useAuth();

  const { sort, order, handleChangeSort } = useSorting();
  const { page, limit, handlePageChange, handleRowsPerPageChange } = usePagination({
    initialPage: 1,
    initialLimit: 20,
  });

  const { salesperson, company, handleSalespersonChange, handleCompanyChange } =
    useInvoiceTableNavigation({ page, limit, sort, order, handlePageChange });

  const { data: invoices = [], isFetching } = useQuery(
    ['invoices', { page, limit, sort, order, salesperson_id: salesperson, customer_name: company }],
    () =>
      getAllInvoices({
        page,
        limit,
        sort,
        order,
        salesperson_id: salesperson,
        customer_name: company,
      }),
  );

  const { mutate: openInvoice, isLoading } = useDownloadInvoice();

  if (isFetching || isLoading) {
    return <Loader />;
  }
  return (
    <Paper>
      <div
        style={{
          padding: '20px 12px',
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          flexWrap: 'wrap',
        }}>
        <Typography variant="h6" id="tableTitle">
          Fakturor
        </Typography>
        {(userMe === 'admin' || userMe === 'salesperson') && (
          <>
            <SalespersonSelect
              data={salespersons}
              value={salesperson}
              onChange={handleSalespersonChange}
              isDesktop={isDesktop}
            />
            <ChooseLeadsCompanyName
              changeCurrentCompanyName={handleCompanyChange}
              defaultValue={company}
              title="Kunder"
            />
          </>
        )}
      </div>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <InvoiceTableHead setSort={handleChangeSort} sort={sort} order={order} role={userMe} />
          <InvoicesTableBody
            data={invoices.data}
            salespersons={salespersons}
            role={userMe}
            openInvoice={openInvoice}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={invoices.pagination?.total || 0}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={(event, newPage) => handlePageChange(newPage)}
        onRowsPerPageChange={(event) => handleRowsPerPageChange(parseInt(event.target.value, 10))}
      />
    </Paper>
  );
};

export default InvoicesTable;
