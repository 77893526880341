export const LEADS_EXPECTATION_NAMES_FOR_CHART = [
  'No category',
  '3-5 leads/30dgr',
  '6-10 leads/30dgr',
  '10+ leads/30dgr',
];

export const COLORS_FOR_CHART = ['#608bd9', '#4caf50', '#ff9800', '#ef534f', '#4caf50'];

export const COLORS_FOR_PER_MONTH_CHART = [
  '#608bd9',
  '#4caf50',
  '#fbc02d',
  '#ff7043',
  '#ef534f',
].reverse();
