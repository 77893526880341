import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import styled from '@emotion/styled';

import { createUser, requestUserInvite } from '../../API/usersApi';
import ChooseLeadsCompanyName from './ChooseLeadsCompanyName';

const style = {
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  p: 8,
  pt: 0,
};

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const CreateNewUser = ({ onClose, kund }) => {
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    role: kund ? 2 : 1,
    isNewEmailFlow: true,
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = 'Please enter an email';
    }
    if (formData.role !== 3 && !formData.companyName) {
      errors.companyName = 'Please enter company name';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmitNewUserFormData = async () => {
    setLoading(true);
    try {
      const dataToSubmit = {
        ...formData,
        companyName: formData.role === 3 ? 'Addictive Group Media AB' : formData.companyName,
      };
      const { data } = await createUser(dataToSubmit);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        role: kund ? 2 : 1,
        isNewEmailFlow: true,
      });
      if (data.id) {
        await requestUserInvite(data.id);
      }
      onClose();
    } catch (err) {
      if (err.message === 'This email already exist') {
        setFormErrors({ email: err.message });
      } else {
        setFormErrors({ undefinedError: err.message });
      }
      console.log(err.message);
    }

    setLoading(false);
  };

  return (
    <Box sx={style}>
      <Box component="form" autoComplete="off">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
          }}>
          <TextField
            label="Förnamn"
            size="small"
            fullWidth
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
          />
          <TextField
            label="Efternamn"
            size="small"
            fullWidth
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
          />
          <TextField
            required
            label="Email"
            size="small"
            fullWidth
            value={formData.email}
            onChange={(e) => {
              setFormErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
              setFormData({ ...formData, email: e.target.value.replaceAll(' ', '').toLowerCase() });
            }}
            error={formErrors.email !== undefined}
            helperText={formErrors.email}
          />
          {formData.role !== 3 && (
            <ChooseLeadsCompanyName
              changeCurrentCompanyName={(companyName) => {
                setFormErrors({ ...formErrors, companyName: '' });
                setFormData({ ...formData, companyName });
              }}
              style={{ width: '100%', margin: '0 0 16px' }}
              isRequire
              withActiveSubscriptions={false}
            />
          )}

          {!kund && (
            <RadioGroup
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              required
              row
              aria-labelledby="role-radio-group"
              defaultValue={1}
              onChange={(e) => setFormData({ ...formData, role: +e.target.value })}>
              <FormControlLabel value={4} control={<Radio />} label="Användare" />
              <FormControlLabel value={3} control={<Radio />} label="Säljare" />
              <FormControlLabel value={1} control={<Radio />} label="Admin" />
            </RadioGroup>
          )}
          {(!!formErrors.undefinedError || !!formErrors.companyName) && (
            <p style={{ textAlign: 'center', color: 'red', margin: 0 }}>
              {formErrors.undefinedError || formErrors.companyName}
            </p>
          )}
        </div>
        {loading ? (
          <Root>
            <CircularProgress color="secondary" size="30px" />
          </Root>
        ) : (
          <div>
            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                if (validateForm()) {
                  handleSubmitNewUserFormData();
                }
              }}>
              Skicka inbjudan
            </Button>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CreateNewUser;
