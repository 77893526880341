import { Card, Typography } from '@mui/material';
import ApexChart from '../../ApexChart';
import { COLORS_FOR_CHART, LEADS_EXPECTATION_NAMES_FOR_CHART } from '../../../constants/chartData';

const LeadExpectationChart = ({ data }) => {
  const options = {
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (val, { dataPointIndex }) => {
          const count = data.count[dataPointIndex];
          return `${count} (${val.toFixed(2)}%)`;
        },
      },
    },
    labels: LEADS_EXPECTATION_NAMES_FOR_CHART,
    colors: COLORS_FOR_CHART,
  };
  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '600px',
        padding: 3,
        '@media (min-width: 960px)': {
          padding: 10,
        },
      }}>
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xs: '18px',
            md: '24px',
          },
        }}
        gutterBottom>
        Leads Expectation Chart
      </Typography>
      <ApexChart data={data.percentage} type="donut" options={options} height="auto" width="auto" />
    </Card>
  );
};

export default LeadExpectationChart;
