import React, { useMemo, memo } from 'react';
import useAuth from '../hooks/useAuth';

const InvoiceAlert = () => {
  const { user } = useAuth();

  const unpaidInvoiceDueDate = user?.unpaid_invoice_due_date;

  const alertMessage = useMemo(() => {
    if (!unpaidInvoiceDueDate) return null;

    const now = new Date();
    const dueDate = new Date(unpaidInvoiceDueDate);
    const diffDays = Math.ceil((now - dueDate) / (1000 * 60 * 60 * 24));

    if (diffDays > 14) {
      return {
        text: (
          <>
            Kära kund. Ni har obetalda fakturor, därför har ni förlorat tillgången till era
            kundförfrågningar.Betala dessa omgående,se fakturorna{' '}
            <a
              href="/client/invoices"
              style={{
                textDecoration: 'underline',
                color: 'inherit',
              }}>
              här
            </a>
            .
          </>
        ),
        backgroundColor: '#f8d7da',
        color: '#721c24',
      };
    }
    if (diffDays > 7) {
      return {
        text: 'Ni har obetalda fakturor. Vänligen betala för att undvika förlora tillgång till era kundförfrågningar.',
        backgroundColor: '#ffe6cc',
        color: '#856404',
      };
    }

    return null;
  }, [unpaidInvoiceDueDate]);

  if (!alertMessage) return null;

  return (
    <div
      style={{
        backgroundColor: alertMessage.backgroundColor,
        padding: '10px',
        borderRadius: '5px',
        color: alertMessage.color,
        fontWeight: 'bold',
        textAlign: 'center',
      }}>
      {alertMessage.text}
    </div>
  );
};

export default memo(InvoiceAlert);
