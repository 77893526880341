import React from 'react';
import { Modal, Box, TextField, Button, Autocomplete } from '@mui/material';
import { companySearchModelAutocompleteKeydown } from '../../utils/companySearchModelAutocompleteKeydown';

const CompanySearchModal = ({
  open,
  onClose,
  companies,
  setSelectedCompany,
  searchTerm,
  setSearchTerm,
  handleCreateProposal,
  selectedCompany,
  isSubmitting,
  isDesktop,
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}>
      <Autocomplete
        sx={{ width: '100%' }}
        options={companies?.length ? companies : []}
        size="small"
        onChange={(e, value) => {
          setSelectedCompany(value);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            value={searchTerm}
            {...params}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) =>
              companySearchModelAutocompleteKeydown(e, searchTerm, handleCreateProposal)
            }
            label="Företag"
          />
        )}
      />
      <Button
        sx={{ whiteSpace: 'nowrap' }}
        variant="contained"
        color="primary"
        onClick={handleCreateProposal}
        disabled={isSubmitting || (searchTerm.length < 3 && !selectedCompany)}>
        {isSubmitting ? 'Creating...' : `Create ${isDesktop ? 'Proposal' : ''}`}
      </Button>
    </Box>
  </Modal>
);

export default CompanySearchModal;
