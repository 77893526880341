import { Alert, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CompanySitesTable from '../CompanySiteTable';
import ChangeOptimizationStageButton from '../OptimizationSiteTableComponents.js/ChangeOptimizationStageButton';
import { OPTIMIZATION_STAGE } from '../../constants/optimizationsStage';
import { getKeyObjectByValue } from '../../utils/getKeyObjectByValue';
import SubscriptionsTable from '../SubscriptionsTable';
import TextAreaWithSignatureButton from '../OptimizationSiteTableComponents.js/TextAreaWithSignatureButton';

const CompanyCardModal = ({
  open,
  onClose,
  getSalespersonNamesString,
  changeComment,
  loadingCell,
  onChange,
  data,
  newComment,
  setNewComment,
}) => (
  <Modal
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    open={!!open}
    onClose={onClose}>
    <div
      style={{
        maxWidth: '95vw',
        maxHeight: '95vh',
        background: 'white',
        padding: '8px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <CloseIcon onClick={onClose} />
      </div>
      {data.subscriptions?.length > 1 && (
        <div
          style={{
            maxWidth: '100%',
          }}>
          <div style={{ overflowY: 'auto' }}>
            <SubscriptionsTable
              subscriptions={data.subscriptions}
              companyName={data.name}
              getSalespersonNamesString={getSalespersonNamesString}
            />
          </div>
        </div>
      )}
      <div style={{ height: '100%', overflow: 'auto', margin: '20px 0' }}>
        <CompanySitesTable companyName={data.name} />
      </div>
      <TextAreaWithSignatureButton
        optimization={data.optimization}
        changeComment={changeComment}
        newComment={newComment}
        setNewComment={setNewComment}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: ' 20px',
        }}>
        <Alert
          sx={{ margin: '15px 0', fontSize: '12px', lineHeight: 1 }}
          icon={false}
          variant="filled"
          severity={data.optimization?.id ? 'warning' : 'success'}>
          {data.optimization?.id
            ? `Optimization stage: ${getKeyObjectByValue({
                object: OPTIMIZATION_STAGE,
                value: data.optimization?.stage,
              })}`
            : 'This company does not need optimization'}
        </Alert>
        <ChangeOptimizationStageButton
          disabled={loadingCell}
          stage={data.optimization?.stage || null}
          onChange={onChange}
          optimizationId={data.optimization?.id || null}
          companyId={data.id}
        />
      </div>
    </div>
  </Modal>
);

export default CompanyCardModal;
