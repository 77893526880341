import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Collapse,
  Container,
  Divider,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { getAllMyLeads, setLeadsNotNew } from '../../API/leadApi';

import { leadsParser } from '../../utils/leadsParser';
import LeadContent from './LeadContent';
import { dateFormatter } from '../../utils/dateFormatter';
import Loader from '../Loader';
import { ACCESS_TOKEN_USER } from '../../const';
import LeadCard from '../Card';
import { getSource } from '../../utils/getSource';
import { LeadsContext } from '../../contexts/LeadsContext';
import { LeadFiles } from './LeadFiles';

const LeadsPage = () => {
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(null);
  const [search, setSearch] = useState('');
  const [isNoPayment, setIsNoPayment] = useState(false);
  const isUserView = useMemo(
    () => window.localStorage.getItem(ACCESS_TOKEN_USER),
    [window.localStorage.getItem(ACCESS_TOKEN_USER)],
  );
  const isDesktop = useMediaQuery('(min-width:600px)');
  const { leads, setLeads, expandedId, setExpandedId, isOpenLead, setIsOpenLead } =
    useContext(LeadsContext);

  useEffect(() => {
    let searching = '';
    let timer = null;

    if (search.length >= 2) {
      searching = search;
    }
    const getLeads = async () => {
      setLoading(true);
      try {
        const res = await getAllMyLeads({ page, limit, search: searching });
        setCount(res.data.count);
        const data = leadsParser(res.data.leads);
        setLeads(data);
      } catch (e) {
        if (e.data.error.includes('invoice overdue by more than 14 days')) {
          setIsNoPayment(true);
        }
        console.log(e);
      }

      setLoading(false);
    };

    timer = setTimeout(() => {
      getLeads();
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [page, limit, search]);

  const handleChangePage = (event, newPage) => {
    if (!isDesktop) {
      return setPage(newPage - 1);
    }
    return setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const tableRowClickHandler = async (id, isNew) => {
    if (isNew && !isUserView) {
      await setLeadsNotNew([id]);
      const newLeads = [...leads];
      newLeads.find((lead) => lead.id === id).isNew = false;
      setLeads(newLeads);
    }

    if (expandedId.findIndex((item) => item === id) === -1) {
      setExpandedId((prev) => [...prev, id]);
    } else {
      setExpandedId((prev) => prev.filter((item) => item !== id));
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          '@media (max-width: 600px)': {
            fontSize: '30px',
          },
        }}
        variant="h1">
        Kundförfrågningar
      </Typography>

      <Divider my={6} sx={{ margin: '24px 0' }} />
      {isDesktop ? (
        <TableContainer padding="none" sx={{ background: 'white' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TextField
                sx={{ margin: '16px' }}
                label="Search"
                variant="outlined"
                onChange={searchInputHandler}
                value={search}
                size="small"
              />
            </TableHead>
            {loading ? (
              <Loader />
            ) : (
              <TableBody>
                {!leads.length ? (
                  <h2 style={{ marginLeft: '16px' }}>
                    {isNoPayment ? (
                      <>
                        Kära kund. Ni har obetalda fakturor, därför har ni förlorat tillgången till
                        era kundförfrågningar.Betala dessa omgående,se fakturorna{' '}
                        <a
                          href="/client/invoices"
                          style={{
                            textDecoration: 'underline',
                            color: 'inherit',
                          }}>
                          här
                        </a>
                        .
                      </>
                    ) : (
                      'No leads'
                    )}
                  </h2>
                ) : (
                  leads.map((lead) => (
                    <React.Fragment key={lead.id}>
                      <TableRow
                        onClick={() => tableRowClickHandler(lead.id, lead.isNew)}
                        style={{
                          backgroundColor:
                            lead.isNew || expandedId.includes(lead.id)
                              ? '#fff'
                              : 'rgba(237, 247, 249, 0.5)',
                        }}>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                            },
                            whiteSpace: 'nowrap',
                            fontWeight: !expandedId.includes(lead.id) && lead.isNew ? 700 : 400,
                            borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                          }}>
                          {getSource(lead.source)}
                        </TableCell>
                        <TableCell
                          sx={{
                            paddingRight: isDesktop ? '35px' : '16px',
                            fontSize: '14px',
                            textAlign: 'right',
                            '@media (max-width: 500px)': {
                              fontSize: '12px',
                            },
                            whiteSpace: 'nowrap',
                            fontWeight: !expandedId.includes(lead.id) && lead.isNew ? 700 : 400,
                            borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                          }}>
                          {dateFormatter(lead.createdAt)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderWidth: expandedId.includes(lead.id) ? 0.3 : 0,
                            backgroundColor:
                              lead.isNew || expandedId.includes(lead.id)
                                ? '#fff'
                                : 'rgba(237, 247, 249, 0.5)',
                          }}
                          colSpan={5}>
                          <Collapse in={expandedId.includes(lead.id)} timeout="auto" unmountOnExit>
                            <LeadContent
                              lead={lead}
                              isOpen={isOpen}
                              setIsOpen={(leadId) => setIsOpen(leadId)}
                            />
                            <LeadFiles lead={lead} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            )}
          </Table>
          {count && (
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
                labelRowsPerPage="Cards per page :"
              />
            </div>
          )}
        </TableContainer>
      ) : (
        <Container>
          {leads.map((lead) => (
            <LeadCard
              key={lead.id}
              lead={lead}
              loading={loading}
              tableRowClickHandler={tableRowClickHandler}
              isClient
              isOpenLead={isOpenLead === lead.id}
              setIsOpenLead={setIsOpenLead}
              isOpenMenu={isOpenMenu === lead.id}
              setIsOpenMenu={setIsOpenMenu}
            />
          ))}
          {count > limit && (
            <Pagination
              sx={{ paddingBottom: '10px', display: 'flex', justifyContent: 'center' }}
              component="div"
              count={Math.ceil(count / limit)}
              page={page + 1}
              onChange={handleChangePage}
              boundaryCount={0}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default LeadsPage;
