import { useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import { getUsersCompanyList } from '../../API/companyApi';

const ChooseLeadsCompanyName = ({
  changeCurrentCompanyName,
  style,
  isRequire,
  defaultValue,
  title,
  withActiveSubscriptions = true,
}) => {
  const [search, setSearch] = useState('');
  const [searchList, setSearchList] = useState([]);
  const isDesktop = useMediaQuery('(min-width:600px)');

  useMemo(async () => {
    const response = await getUsersCompanyList({
      search,
      page: 0,
      type: '',
      limit: 50,
      withActiveSubscriptions,
    });
    setSearchList(response.companies);
  }, [search]);

  const onSetCurrentCompanyNameHandler = (event, site) => {
    changeCurrentCompanyName(site?.name);
    setSearch(site?.name);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      options={search?.length > 2 ? searchList : []}
      sx={{ width: isDesktop ? 300 : '100%', margin: '12px', ...style }}
      size="small"
      onChange={onSetCurrentCompanyNameHandler}
      renderInput={(params) => (
        <TextField
          value={search}
          {...params}
          onChange={(e) => setSearch(e.target.value)}
          label={title || `Företag${isRequire && ' *'}`}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      defaultValue={{ name: defaultValue || '' }}
    />
  );
};

export default ChooseLeadsCompanyName;
