import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Settings from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';

import {
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Tooltip,
  Box,
  CircularProgress,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { getUsersList, requestUserInvite } from '../../API/usersApi';
import CreateNewUser from './CreateUser';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ROLENAME = { 1: 'Admin', 3: 'Säljare', 4: 'Användare' };

const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [openCreateUser, setOpenCreateUser] = useState(false);

  const [loadingCell, setLoadingCell] = useState(null);
  const [successInviteList, setSuccessInviteList] = useState([]);
  const { userMe } = useAuth();
  const navigate = useNavigate();

  const updateUser = async () => {
    setLoadingCell(0);
    const data = await getUsersList({ page, limit, role: -2 });
    setCount(data.count);
    setUsersList(data.users);
    setLoadingCell(null);
  };

  useEffect(() => {
    updateUser();
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onCloseModal = async () => {
    setOpenCreateUser(false);
    await updateUser();
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const getName = (firstName, lastName, email) => {
    if (lastName) {
      if (firstName) {
        return `${firstName} ${lastName}`;
      }
      return lastName;
    }
    if (firstName) {
      return firstName;
    }
    return email;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          flexWrap: 'wrap',
        }}>
        {' '}
        <Typography
          sx={{
            fontWeight: 500,
            '@media (max-width: 600px)': {
              fontSize: '30px',
            },
          }}
          variant="h1">
          Användare
        </Typography>
      </div>

      <Card mb={6}>
        {userMe === 'admin' && (
          <div
            style={{
              margin: '16px',

              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button
              sx={{
                marginRight: '8px',
                padding: '6px 10px',
                whiteSpace: 'nowrap',
                flexShrink: 0,
                maxHeight: '36px',
              }}
              variant="contained"
              onClick={() => setOpenCreateUser(!openCreateUser)}>
              Skapa användare
            </Button>
          </div>
        )}
        {openCreateUser && <CreateNewUser onClose={onCloseModal} />}
        {loadingCell === 0 ? (
          <Box sx={{ width: '22px', height: '50px', margin: '50px auto 0' }}>
            <CircularProgress sx={{ width: '100%', height: '100%' }} />
          </Box>
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Användare</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', width: '150px' }}>Status</TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap', width: '150px' }}>Role</TableCell>
                  <TableCell sx={{ width: '150px' }} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList?.map((row, userIndex) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {getName(row.firstName, row.lastName, row.email)}
                    </TableCell>
                    <TableCell>{row.isActive ? 'Aktiv' : 'Inaktiv'}</TableCell>
                    <TableCell>{ROLENAME[row.role] || ''}</TableCell>

                    <TableCell align="center">
                      <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <div
                          style={{
                            justifyContent: 'end',
                            width: '90px',
                            display: 'flex',
                            gap: '10px',
                            margin: 0,
                          }}>
                          {!row.isInvited && loadingCell !== row.id && (
                            <Tooltip title="Skicka inbjudan" arrow>
                              <button
                                type="button"
                                style={{ padding: 0, backgroundColor: 'transparent', border: 0 }}
                                disabled={successInviteList.includes(row.id)}
                                onClick={async () => {
                                  if (userMe === 'admin') {
                                    setLoadingCell(row.id);

                                    await requestUserInvite(row.id);

                                    setLoadingCell(null);
                                    setUsersList((prev) => {
                                      const newValue = [...prev];
                                      newValue[userIndex] = {
                                        ...newValue[userIndex],
                                        isInviteSent: true,
                                      };
                                      return newValue;
                                    });
                                    setSuccessInviteList((prev) => [...prev, row.id]);
                                  }
                                }}>
                                <EmailIcon
                                  sx={{
                                    cursor: 'pointer',
                                    fill: row.isInviteSent ? '#00B8B4' : '#646363',
                                  }}
                                />
                              </button>
                            </Tooltip>
                          )}

                          {row.role !== 1 || userMe === 'admin' ? (
                            <Tooltip title="Inställningar" arrow>
                              <Settings
                                sx={{ cursor: 'pointer', fill: '#646363' }}
                                onClick={() => {
                                  navigate(`/${userMe}/users/${row.id}`);
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <div style={{ width: '22.28px' }} />
                          )}

                          {loadingCell === row.id && (
                            <Box>
                              <CircularProgress
                                sx={{ height: '22px !important', width: '22px !important' }}
                              />
                            </Box>
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {count && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                />
              </div>
            )}
          </TableWrapper>
        )}
      </Card>
    </>
  );
};

export default UsersList;
