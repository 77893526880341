import { Divider, Grid, Typography } from '@mui/material';
import InvoicesTable from '../invoiceTable/InvoicesTable';
import StatsCard from '../invoiceTable/StatsCart';

const AdminInvoicesPage = () => (
  <Grid sx={{ padding: '20px' }}>
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
        <Typography variant="h3" gutterBottom display="inline">
          Fakturor
        </Typography>
      </Grid>
    </Grid>
    <Divider sx={{ my: 6 }} />

    <StatsCard />
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <InvoicesTable />
      </Grid>
    </Grid>
  </Grid>
);

export default AdminInvoicesPage;
