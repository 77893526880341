export const dateFormatter = (createdAt) => {
  const date = new Date(createdAt);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const result = `${day} ${month} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  return result;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const formatDateForTable = (fullDate) => {
  const date = new Date(fullDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}-${month}-${year}`;
};
export const generateMonthOptions = () => {
  const now = new Date();
  const options = [];

  for (let i = 0; i <= 12; i += 1) {
    const currentDate = new Date(now.getFullYear(), now.getMonth() - i, 1);

    const startDateValue = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1,
    ).padStart(2, '0')}-01`;
    const endOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const endDateValue = `${endOfMonthDate.getFullYear()}-${String(
      endOfMonthDate.getMonth() + 1,
    ).padStart(2, '0')}-${String(endOfMonthDate.getDate()).padStart(2, '0')}`;

    const label = currentDate.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });

    options.push({ startDate: startDateValue, endDate: endDateValue, label });
  }

  return options;
};
