import axios from '../utils/axios';

export const getLeadsExpectationChartData = async () => {
  const { data } = await axios.get('/api/my-pages/dashboard/leads-expectation-chart');
  return data;
};

export const getLeadsPerMonthChartData = async ({ startDate, endDate }) => {
  const { data } = await axios.get('/api/my-pages/dashboard/leads-per-month', {
    params: { startDate, endDate },
  });
  return data;
};
