import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SalespersonSelect = ({ data, value, onChange, isDesktop = true }) => (
  <FormControl
    sx={{
      width: isDesktop ? '150px' : '100%',
      margin: '0 12px',
    }}>
    <InputLabel id="salesperson-label">Användare</InputLabel>
    <Select
      sx={{ background: 'white' }}
      size="small"
      labelId="salesperson-label"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label="Användare">
      <MenuItem value={0}>Alla</MenuItem>
      {data?.map((sales) => (
        <MenuItem key={sales.id} value={sales.id}>
          {sales.firstName} {sales.lastName}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SalespersonSelect;
