import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { createCompany, getCompanies } from '../../API/companyApi';
import { createProposal } from '../../API/proposalApi';
import useAuth from '../useAuth';

const useCompanySearchModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { userMe } = useAuth();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigate = useNavigate();

  const { data: companies } = useQuery(
    ['companies', searchTerm],
    () => getCompanies({ search: searchTerm.trim() }),
    { enabled: !!searchTerm },
  );

  const mutation = useMutation((companyId) => createProposal({ companyId }), {
    onSuccess: (data) => {
      navigate(`/${userMe}/proposal/${data.proposal.id}`);
    },
  });

  const createCompanyMutation = useMutation((name) => createCompany(name), {
    onSuccess: (newCompany) => {
      mutation.mutate(newCompany.id);
    },
  });

  const handleCreateProposal = () => {
    if (selectedCompany) {
      mutation.mutate(selectedCompany.id);
    } else {
      const existingCompany = companies?.find(
        (company) => company.name.toLowerCase() === searchTerm.trim().toLowerCase(),
      );

      if (existingCompany) {
        mutation.mutate(existingCompany.id);
      } else {
        createCompanyMutation.mutate(searchTerm);
      }
    }
  };

  useEffect(() => {
    if (selectedCompany) setSelectedCompany(null);
  }, [searchTerm]);

  return {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    companies,
    handleCreateProposal,
    setSearchTerm,
    searchTerm,
    setSelectedCompany,
    selectedCompany,
    isSubmitting: mutation.isLoading,
    isDesktop,
  };
};

export default useCompanySearchModal;
