import { Paper } from '@mui/material';

import Loader from '../Loader';

import useOptimizationSiteTable from '../../hooks/Optimization/useOptimizationSitesTable';
import CompanySitesTable from '../CompanySiteTable';
import ChangeOptimizationStageButton from './ChangeOptimizationStageButton';
import TextAreaWithSignatureButton from './TextAreaWithSignatureButton';
import OptimizationHistory from './OptimizationHistory';

const OptimizationSitesTable = ({
  companyName,
  comment,
  optimizationsId,
  status,
  changeComment,
  changeStage,
}) => {
  const { loading, sites, stage, saveStage } = useOptimizationSiteTable({
    companyName,
    comment,
    optimizationsId,
    status,
    changeComment,
    changeStage,
  });

  if (loading || !sites) {
    return null;
  }

  return (
    <Paper sx={{ pb: 5 }}>
      <CompanySitesTable companyName={companyName} />
      <>
        <TextAreaWithSignatureButton
          optimization={{ id: optimizationsId, comment }}
          changeComment={changeComment}
        />
        {loading ? (
          <Loader />
        ) : (
          <div style={{ display: 'flex', float: 'right' }}>
            <ChangeOptimizationStageButton
              disabled={loading}
              stage={stage}
              onChange={saveStage}
              optimizationId={optimizationsId}
            />
          </div>
        )}
        <OptimizationHistory optimizationId={optimizationsId} />
      </>
    </Paper>
  );
};

export default OptimizationSitesTable;
