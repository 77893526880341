import React, { useEffect, useState } from 'react';
import {
  Card,
  Collapse,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { deleteLead, getAllMyLeads, getSiteLeads } from '../../API/leadApi';
import { leadsParser } from '../../utils/leadsParser';
import { LEADS_STATUS } from '../../constants/leadConstants';
import { dateFormatter } from '../../utils/dateFormatter';
import LeadContent from '../UserPage/LeadContent';
import ChooseLeadsSite from './ChooseLeadsSite';
import Loader from '../Loader';
import ChooseLeadsCompanyName from './ChooseLeadsCompanyName';
import useAuth from '../../hooks/useAuth';
import { LeadFiles } from '../UserPage/LeadFiles';
import LeadCard from '../Card';
import { getSource } from '../../utils/getSource';

const LeadsList = () => {
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [expandedId, setExpandedId] = useState([]);
  const [currentSite, setCurrentSite] = useState();
  const [loading, setLoading] = useState(0);
  const [currentCompanyName, setCurrentCompanyName] = useState('0');
  const [leadsStatus, setLeadsStatus] = useState(2);
  const [getAll, setGetAll] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [isOpenLead, setIsOpenLead] = useState(null);
  const { userMe } = useAuth();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const getAllLeads = async () => {
    try {
      let query = {
        page,
        limit,
      };
      if (currentSite) {
        query = {
          ...query,
          siteId: currentSite.id,
        };
      }
      if (currentCompanyName !== '0') {
        query = {
          ...query,
          companyName: currentCompanyName,
        };
      }
      if (keyword) {
        query = {
          ...query,
          keyword,
        };
      }
      if (LEADS_STATUS[leadsStatus]) {
        query.withClient = leadsStatus;
      }

      const response = await getSiteLeads({ ...query });
      setCount(response.count);
      const data = leadsParser(response.leads);
      setLeads(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSalespersonLeads = async () => {
    setLoading(true);
    try {
      const res = await getAllMyLeads({ page, limit });
      setCount(res.data.count);
      const data = leadsParser(res.data.leads);
      setLeads(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getAll === 1) {
      getSalespersonLeads();
    } else {
      getAllLeads();
    }
  }, [currentSite, page, limit, currentCompanyName, getAll, keyword, leadsStatus]);

  const handleChangePage = (event, newPage) => {
    if (!isDesktop) {
      return setPage(newPage - 1);
    }
    return setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const tableRowClickHandler = (id) => {
    if (expandedId.findIndex((item) => item === id) === -1) {
      setExpandedId((prev) => [...prev, id]);
    } else {
      setExpandedId((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleChange = (site) => {
    setPage(0);
    setCurrentSite(site);
  };
  const handleChangeCompany = (companyName) => {
    setPage(0);
    setCurrentCompanyName(companyName);
  };

  const deleteLeadHandler = async (leadId) => {
    setLoading(leadId);
    try {
      await deleteLead(leadId);
      await getAllLeads();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(0);
    }
  };

  const keywordInputHandler = (e) => {
    setPage(0);
    setKeyword(e.target.value);
  };

  const leadsStatusHandler = (e) => {
    setPage(0);
    setLeadsStatus(e.target.value);
  };

  return (
    <>
      <Typography
        sx={{
          margin: '0 0 20px',
          fontWeight: 500,
          '@media (max-width: 600px)': {
            fontSize: '30px',
          },
        }}
        variant="h1"
        gutterBottom>
        Kundförfrågningar
      </Typography>

      <Divider my={6} sx={{ margin: '24px 0' }} />
      <Card>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {userMe === 'salesperson' && (
            <FormControl sx={{ width: '130px', margin: '12px' }}>
              <Select size="small" value={getAll} onChange={(e) => setGetAll(e.target.value)}>
                <MenuItem value={0}>Get All</MenuItem>
                <MenuItem value={1}>Get Only My</MenuItem>
              </Select>
            </FormControl>
          )}
          {getAll === 0 && (
            <>
              <ChooseLeadsSite changeCurrentSite={(e) => handleChange(e)} />
              {userMe === 'admin' && (
                <ChooseLeadsCompanyName changeCurrentCompanyName={(e) => handleChangeCompany(e)} />
              )}
              <div
                style={{
                  margin: '12px',
                  display: 'flex',
                  gap: '20px',
                  flexDirection: isDesktop ? 'row' : 'column',
                  width: !isDesktop && '100%',
                }}>
                <TextField
                  sx={{ width: isDesktop ? 200 : '100%' }}
                  id="standard-basic"
                  label="Keyword"
                  variant="outlined"
                  onChange={keywordInputHandler}
                  value={keyword}
                  size="small"
                />
                {userMe === 'admin' && (
                  <FormControl>
                    <InputLabel>Lediga</InputLabel>
                    <Select
                      size="small"
                      value={leadsStatus}
                      onChange={leadsStatusHandler}
                      label="Lediga">
                      <MenuItem value={2}>All sidor</MenuItem>
                      <MenuItem
                        value={1}
                        sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                        Lediga sidor
                      </MenuItem>
                      <MenuItem
                        value={0}
                        sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                        Inte Lediga sidor
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>
            </>
          )}
        </div>

        {!leads.length ? <h2 style={{ paddingLeft: '20px' }}>No leads</h2> : null}
        {isDesktop ? (
          <TableContainer padding="none">
            <Table sx={{ background: 'white' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {!currentSite?.domain && <TableCell sx={{ fontSize: '16px' }}>Sidor</TableCell>}
                  <TableCell sx={{ fontSize: '16px' }}>Kundförfrågningar</TableCell>
                  <TableCell sx={{ width: '100%', fontSize: '16px' }}>Ämnesrad</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>Datum</TableCell>
                  {userMe === 'admin' && <TableCell sx={{ fontSize: '16px' }}> </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead) => (
                  <React.Fragment key={lead.id}>
                    <TableRow
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                        fontWeight: !expandedId.includes(lead.id) ? 700 : 400,
                      }}
                      onClick={() => tableRowClickHandler(lead.id)}>
                      {!currentSite?.domain && (
                        <TableCell
                          sx={{
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                            borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                            fontWeight: !expandedId.includes(lead.id) ? 700 : 400,
                          }}>
                          {lead.domain}
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                          fontWeight: !expandedId.includes(lead.id) ? 700 : 400,
                        }}>
                        {getSource(lead.source)}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                          fontSize: '14px',
                          fontWeight: !expandedId.includes(lead.id) ? 700 : 400,
                        }}>
                        {lead.sendersEmail || ''}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          borderWidth: expandedId.includes(lead.id) ? 0 : 0.3,
                          fontSize: '14px',
                          fontWeight: !expandedId.includes(lead.id) ? 700 : 400,
                          paddingRight: isDesktop ? '35px' : '16px',
                        }}>
                        {dateFormatter(lead.createdAt)}
                      </TableCell>
                      {userMe === 'admin' && (
                        <TableCell sx={{ borderWidth: expandedId.includes(lead.id) ? 0 : 0.3 }}>
                          <Tooltip title="Delete" arrow>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={(e) => {
                                deleteLeadHandler(lead.id);
                                e.stopPropagation();
                              }}>
                              {loading === lead.id ? (
                                <div style={{ width: '18px', height: '18px' }}>
                                  <Loader />
                                </div>
                              ) : (
                                <Close fontSize="small" htmlColor="#000000de" />
                              )}
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>

                    <TableRow
                      sx={{
                        backgroundColor: '#fff',
                      }}>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          borderTop: 'white',

                          borderWidth: expandedId.includes(lead.id) ? 0.3 : 0,
                        }}
                        colSpan={5}>
                        <Collapse in={expandedId.includes(lead.id)} timeout="auto" unmountOnExit>
                          <LeadContent lead={lead} />
                          <LeadFiles lead={lead} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            {count && (
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleChangeLimit}
                  labelRowsPerPage="Cards per page :"
                />
              </div>
            )}
          </TableContainer>
        ) : (
          <Container>
            {leads.map((lead) => (
              <LeadCard
                key={lead.id}
                lead={lead}
                deleteLeadHandler={deleteLeadHandler}
                loading={loading}
                tableRowClickHandler={tableRowClickHandler}
                isOpenLead={isOpenLead === lead.id}
                setIsOpenLead={setIsOpenLead}
              />
            ))}
            <Pagination
              sx={{ paddingBottom: '10px', display: 'flex', justifyContent: 'center' }}
              component="div"
              count={count}
              page={page + 1}
              onChange={handleChangePage}
              boundaryCount={0}
            />
          </Container>
        )}
      </Card>
    </>
  );
};

export default LeadsList;
