export const checkBadPayers = (data, badPayers) => {
  const updatedData = data.map((item) => {
    const companyName = item.companyName;

    if (badPayers && badPayers.companies[companyName]) {
      const companyData = badPayers.companies[companyName];

      return {
        ...item,
        unpaid_invoice_due_date: companyData.unpaid_invoice_due_date,
        unpaid_invoice_immunity: companyData.unpaid_invoice_immunity,
      };
    }

    return item;
  });

  return updatedData;
};
