import { useState } from 'react';

export const useSorting = () => {
  const [sort, setSort] = useState('');
  const [order, setOrder] = useState('DESC');

  const handleChangeSort = (value) => {
    if (value === sort) {
      setOrder((prev) => (prev === 'DESC' ? 'ASC' : 'DESC'));
    } else {
      setOrder('DESC');
      setSort(value);
    }
  };

  return {
    sort,
    order,
    handleChangeSort,
  };
};
