import { useEffect, useState } from 'react';
import { getSalesperson } from '../API/usersApi';

export const useSalespersons = () => {
  const [salespersons, setSalespersons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAllSalesperson = async () => {
      try {
        const { data } = await getSalesperson();
        setSalespersons(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    getAllSalesperson();
  }, []);

  return { salespersons, loading, error };
};
