export const OPTIMIZATION_STAGE = {
  PENDING: 0,
  BESTÄLLNING: 1,
  PRODUCTION: 2,
  LIVE: 3,
  LOST: 4,
};

export const OPTIMIZATION_STAGE_NAMES = Object.fromEntries(
  Object.entries(OPTIMIZATION_STAGE).map(([key, value]) => [value, key]),
);
